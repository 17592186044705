<script setup>
  import {
    computed
  } from 'vue'

  const props = defineProps({
    size: {
      default: 'medium',
      type: String
    },
  })

  const spinnerStyle = computed(() => {
    const size = { small: '3rem', medium: '6rem', large: '9rem' }[props.size]
    return { width: size, height :size }
  })
</script>
<template>
  <div class="d-flex justify-content-center">
    <div class="spinner-border text-secondary" :style="spinnerStyle" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</template>
